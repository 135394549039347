<template>
    <div class="navigation d-flex">
        <router-link
            :to="{ name: routeName }"
            class="navigation__parent-link"
        >
            {{ parentPageName }}
        </router-link>

        <span class="d-inline-block mx-2">
            <img src="../../assets/views/innovative_partnership/arrow-right.svg" alt="">
        </span>

        <span class="navigation__current-nav">{{ currentPageName }}</span>
    </div>
</template>

<script>

/**
 * Компонент для навигации от родительской страницы к дочерней
 * @vue-prop {String} routeName - Название маршрута к родительской странице
 * @vue-prop {String} parentPageName - Название родительской страницы
 * @vue-prop {String} currentPageName - Название дочерней страницы
 */
export default {
    name: "Nav",

    props: {
        routeName: {
            type: String,
            required: true
        },

        parentPageName: {
            type: String,
            required: true
        },

        currentPageName: {
            type: String,
            required: true
        },
    }
}
</script>
