<template>
    <section class="highpark">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="ИТМО Хайпарк"
        />

        <div class="highpark__main-img d-none d-xl-flex">
            <div class="highpark__blurred-img mt-3 mt-lg-auto ms-lg-auto d-flex align-items-lg-end">
                <div class="research-center__title mt-lg-auto ms-lg-5 mb-lg-5">
                    <div>
                        <a
                            class="research-center__link"
                            target="_blank"
                            href="https://hp.itmo.ru"
                        >
                            Перейти на сайт
                        </a>
                    </div>
                    {{ getHighparkPageInfo('title') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <div class="research-center__title research-center__title--dark">
                <div>
                    <a
                        class="research-center__link"
                        target="_blank"
                        href="https://hp.itmo.ru"
                    >
                        Перейти на сайт
                    </a>
                </div>
                {{ getHighparkPageInfo('title') }}
            </div>
        </div>

        <div class="highpark-about-block d-flex flex-column flex-lg-row my-3 my-lg-5">
            <div class="col-12 col-lg-7 col-xl-6">
                <img
                    class="highpark-about-block__img"
                    src="../../assets/views/highpark/about.png"
                    alt=""
                >
            </div>

            <div class="highpark-about d-flex flex-column me-3 mt-4 mt-lg-0">
                <h3 class="highpark-about__title mt-xl-4">{{ getHighparkPageInfo('subtitle_1') }}</h3>
                <div class="highpark-about__text mt-3">
                    {{ getHighparkPageInfo('subtext_1') }}
                </div>
                <div class="main-task__title mt-4">{{ getHighparkPageInfo('subtitle_2') }}</div>
                <div class="main-task__description mt-3">
                    {{ getHighparkPageInfo('subtext_2') }}
                </div>
            </div>
        </div>

        <div class="highpark-structure py-4">
            <h3 class="highpark-structure__title">{{ getHighparkPageInfo('subtitle_3') }}</h3>

            <div class="d-flex flex-column flex-md-row mt-4">
                <div class="stucture">
                    <img class="highpark-structure__img"
                         src="../../assets/views/highpark/campus.png"
                         alt=""
                         width="100%">
                    <div class="stucture__title mt-4">
                        {{ getHighparkProjectStructureInfo(1, 'title') }}
                    </div>
                    <div class="stucture__text mt-2">
                        {{ getHighparkProjectStructureInfo(1, 'text') }}
                    </div>
                </div>

                <div class="stucture ms-md-4 mt-4 mt-md-0">
                    <img
                        class="highpark-structure__img"
                        src="../../assets/views/highpark/valley.png"
                        alt=""
                        width="100%">
                    <div class="stucture__title mt-4">{{ getHighparkProjectStructureInfo(2, 'title') }}</div>
                    <div class="stucture__text mt-2">
                        {{ getHighparkProjectStructureInfo(1, 'text') }}
                    </div>
                </div>

                <div class="stucture ms-md-4 mt-4 mt-md-0 me-0">
                    <img
                        class="highpark-structure__img"
                        src="../../assets/views/highpark/business-park.png"
                        alt=""
                        width="100%"
                    >
                    <div class="stucture__title mt-4">{{ getHighparkProjectStructureInfo(3, 'title') }}</div>
                    <div class="stucture__text mt-2">
                        {{ getHighparkProjectStructureInfo(3, 'text') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="highpark-tasks-block d-flex flex-column flex-lg-row align-items-center my-5">
            <div class="col-12 col-lg-8 col-xl-6">
                <img
                    class="highpark-tasks__img"
                    src="../../assets/views/highpark/tasks.png"
                    alt=""
                    width="673"
                    height="458"
                >
            </div>

            <div
                class="highpark-tasks col-12 col-lg-4 col-xl-5 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0 me-xxl-5 ms-lg-4 ms-xl-auto">
                <div class="highpark-tasks__title mt-4">{{ getHighparkPageInfo('subtitle_4') }}</div>

                <ul class="highpark-tasks__list mt-4">
                    <li
                        v-for="(task, index) in getHighparkPageInfo('tasks_list_split')"
                        :key="index"
                        class="highpark-tasks__item mt-3"
                    >
                        {{ task }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="highpark-opportunities d-flex flex-column flex-xl-row pt-4 pb-5">
            <div class="col-12 col-xl-3 me-5">
                <div class="highpark-opportunities__text">
                    {{ getHighparkPageInfo('subtitle_5') }}
                </div>
            </div>

            <div class="highpark-opportunities__card d-flex justify-content-center d-flex justify-content-xl-start col-12 col-xl-3 mt-5 mt-xl-0">
                <div class="opportunities-card">
                    <div class="position-relative">
                        <img
                            class="number position-absolute"
                            src="../../assets/views/highpark/number1.png"
                            alt=""
                            width="63"
                            height="63"
                        >
                        <img
                            class="line position-absolute"
                            src="../../assets/views/highpark/line.svg"
                            alt=""
                        >
                        <img src="../../assets/views/highpark/image1.png" alt="">
                    </div>
                    <div class="opportunities-card__text mt-4 ms-3">
                        {{ getClusterDevelopmentOption(1, 'description') }}
                    </div>
                </div>
            </div>

            <div class="highpark-opportunities__card d-flex justify-content-center d-flex justify-content-xl-start col-12 col-xl-3 mt-4 mt-xl-0">
                <div class="opportunities-card">
                    <div class="position-relative">
                        <img
                            class="number position-absolute"
                            src="../../assets/views/highpark/number2.png"
                            alt=""
                            width="63"
                            height="63"
                        >
                        <img
                            class="line position-absolute"
                            src="../../assets/views/highpark/line.svg"
                            alt=""
                        >
                        <img src="../../assets/views/highpark/image2.png" alt="">
                    </div>
                    <div class="opportunities-card__text mt-4 ms-3">
                        {{ getClusterDevelopmentOption(2, 'description') }}
                    </div>
                </div>
            </div>

            <div class="highpark-opportunities__card d-flex justify-content-center d-flex justify-content-xl-start col-12 col-xl-2 mt-4 mt-xl-0">
                <div class="opportunities-card">
                    <div class="position-relative">
                        <img
                            class="number position-absolute"
                            src="../../assets/views/highpark/number3.png"
                            alt=""
                            width="63"
                            height="63"
                        >
                        <img
                            class="line position-absolute"
                            src="../../assets/views/highpark/line.svg"
                            alt=""
                        >
                        <img src="../../assets/views/highpark/image3.png" alt="">
                    </div>
                    <div class="opportunities-card__text mt-4 ms-3">
                        {{ getClusterDevelopmentOption(3, 'description') }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "Highpark",

    components: {
        Nav
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();
        const store = useStore()

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('widgets/getClusterDevelopmentOptionsWidgets')
        const clusterDevelopmentOptionsWidgets = computed(() => store.getters['widgets/clusterDevelopmentOptionsWidgets'])

        const getClusterDevelopmentOption = (id, key) => {
            if (clusterDevelopmentOptionsWidgets.value) {
                return clusterDevelopmentOptionsWidgets.value.items.find(item => item.id === id)[key];
            }

            return ''
        }

        store.dispatch('pages/getHighparkPage')
        const highparkPage = computed(() => store.getters['pages/highparkPage'])

        const getHighparkPageInfo = (key) => {
            if (highparkPage.value) {
                return highparkPage.value[key]
            }

            return ''
        }

        const getHighparkProjectStructureInfo = (id, key) => {
            if (highparkPage.value) {
                return highparkPage.value.project_structure.items.find(item => item.id === id)[key]
            }

            return ''
        }

        return {
            getHighparkPageInfo,
            getClusterDevelopmentOption,
            getHighparkProjectStructureInfo
        }
    }
}
</script>
